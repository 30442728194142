import proj1 from './proj1.png';
import proj2 from './proj2.jpeg';
import proj3 from './proj3.webp';
import proj4 from './proj4.webp';
import proj5 from './proj5.jpeg';
import proj6 from './proj6.png';

export default{
    proj1, 
    proj2,
    proj3,
    proj4,
    proj5,
    proj6
}
